
import { defineComponent } from "vue";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);

let updateTimer: any = null;

export default defineComponent({
  props: ["id", "q1", "q2", "q3", "q4"],
  created() {
    //
  },
  data() {
    return {
      chart: null as any,
    };
  },
  computed: {
    overall(): number {
      return (
        parseInt(this.q1) +
        parseInt(this.q2) +
        parseInt(this.q3) +
        parseInt(this.q4)
      );
    },
  },
  watch: {
    q1(value) {
      // console.log(value, "q1");
      !isNaN(value) && this.update();
    },
    q2(value) {
      // console.log(value, "q2");
      !isNaN(value) && this.update();
    },
    q3(value) {
      // console.log(value, "q3");
      !isNaN(value) && this.update();
    },
    q4(value) {
      // console.log(value, "q4");
      !isNaN(value) && this.update();
    },
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      // work_style_chart_1_6
      // console.log(this.id, "this.id");
      // console.log(this.q1, this.q4, this.q2, this.q3);

      // console.log(this.chart, "this.chart");
      // const canvas = this.$refs.doughnut_canvas;
      const canvas = document.getElementById(this.id);
      const currentChart = Chart.getChart(this.id);

      try {
        let data = [25, 25, 25, 25];
        if (currentChart != undefined) {
          data = [this.q1, this.q2, this.q3, this.q4];
          // this.chart.destroy();
          currentChart.destroy();
        } else {
          if (!(this.q1 == 0 && this.q2 == 0 && this.q3 == 0 && this.q4 == 0)) {
            data = [this.q1, this.q2, this.q3, this.q4];
          }
        }

        if (!(canvas as any)) {
          console.log("no canvas");
          return;
        }

        const ctx = (canvas as any).getContext("2d");
        // console.log(ctx, "ctx");
        const options = {
          cutout: "70%",
          plugins: {
            datalabels: {
              color: "#FFFFFF",
              // font: {
              //   weight: "bold",
              //   size: "12px",
              //   family: "Urbanist",
              // },
            },
            tooltip: {
              enabled: false,
            },
          },
          fullSize: true,
          layout: {
            padding: 0,
          },
        };
        this.chart = new Chart(ctx, {
          type: "doughnut",
          // canvas: {
          //   width: "312px",
          //   height: "312px",
          // },
          data: {
            datasets: [
              {
                data: data,
                backgroundColor: ["#FF4343", "#22BC66", "#FFC200", "#5EB8F6"], // red, green, yellow, blue
                hoverOffset: 4,
                borderWidth: 6,
                borderRadius: 8,
              },
            ],
            // labels: ["q1", "q2", "q3", "q4"],
          },
          options: options,
        });
        // console.log(this.chart, "this.chart");
      } catch (e) {
        console.log(e);
      }
    },
    update() {
      // console.log("update()");
      if (updateTimer) {
        // console.log("clearTimeout()");
        clearTimeout(updateTimer);
      }

      const delay = 800;
      updateTimer = setTimeout(() => {
        // console.log("update(): timer");
        // this.chart.data.datasets.forEach((dataset: any) => {
        //   console.log(dataset, "dataset");
        //   dataset.data = [this.q1, this.q4, this.q2, this.q3];
        // });
        // this.chart.update();
        this.render();
      }, delay);
    },
  },
});
